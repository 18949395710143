import moment from 'moment-timezone';
import _ from 'lodash';

// Accepts start and end timestamps, returns formatted date and time range string
const getFormattedStartEndDate = ({
  dateFormat = 'l',
  start,
  end,
  timezone: tzInput,
  showTimezone = false,
  separator = '-',
}) => {
  const timezone = tzInput || moment.tz.guess();
  const startDay = moment(start).tz(timezone).format(dateFormat);
  const endDay = moment(end).tz(timezone).format(dateFormat);
  const startTime = moment(start).tz(timezone).format('LT');
  const endTime = moment(end)
    .tz(timezone)
    .format(showTimezone ? 'LT z' : 'LT');
  return startDay === endDay
    ? `${startDay}, ${startTime} ${separator} ${endTime}`
    : `${moment(start)
        .tz(timezone)
        .format(`${dateFormat} h:mm A`)} ${separator} ${moment(end)
        .tz(timezone)
        .format(`${dateFormat} h:mm A${showTimezone ? ' z' : ''}`)}`;
};

// Accepts start and end moment objects, returns formatted date range string
const formatDateRange = ({ start, end, includeDayOfWeek = false }) => {
  const endDate = end.clone().subtract(1, 'day');

  // If range is 1 day
  if (start.isSame(endDate, 'day')) return start.format('MMMM D, YYYY');

  let startFormat = 'MMMM D';
  let endFormat = 'D, YYYY';
  if (start.month() !== endDate.month()) {
    startFormat = 'MMM D';
    endFormat = 'MMM D, YYYY';

    if (start.year() !== endDate.year()) {
      startFormat = 'MMM D, YYYY';
    }
  }

  if (includeDayOfWeek) {
    startFormat = 'ddd, ' + startFormat;
  }

  return _.concat(start.format(startFormat), endDate.format(endFormat)).join(
    ' - ',
  );
};

const formatDuration = (val, unit) => {
  const numMinutes = moment.duration(val, unit).minutes();
  const numHours = moment.duration(val, unit).hours();
  const numDays = moment.duration(val, unit).days();

  return _.compact([
    numDays > 0 && `${numDays} ${numDays === 1 ? 'Day' : 'Days'}`,
    numHours > 0 && `${numHours} ${numHours === 1 ? 'Hour' : 'Hours'}`,
    numMinutes > 0 &&
      `${numMinutes} ${numMinutes === 1 ? 'Minute' : 'Minutes'}`,
  ]).join(', ');
};

/**
 * Formatting function to decouple date formatting to specific library
 */
const formatDate = (pattern: string, date: Date): string =>
  moment(date).format(pattern);

const addToDate = (amount, unitOfTime, date: Date = undefined): Date => {
  const srcDate = date ? moment(date) : moment();
  return srcDate.add(amount, unitOfTime).toDate();
};

export function spreadMinutesToHours(totalMinutes) {
  if (!totalMinutes) return { hours: 0, minutes: 0 };
  const minutes = totalMinutes % 60;
  const hourMinutes = totalMinutes - minutes;
  const hours = hourMinutes / 60;

  return { hours, minutes };
}

export function getTotalMinutes({ hours = 0, minutes = 0 }) {
  const h = hours * 60;
  return _.toSafeInteger(h + minutes);
}

export const timestampTextRelative = (timestamp: number | Date): string => {
  let tsText = moment(timestamp).format('MM/DD/YYYY');
  if (moment(timestamp).isSame(moment(), 'day')) {
    // same day
    tsText = 'Today';
  } else if (moment(timestamp).add({ days: 1 }).isSame(moment(), 'day')) {
    // within the last day
    tsText = 'Yesterday';
  } else if (moment(timestamp).add({ days: 7 }).isAfter(moment())) {
    // within the last week
    tsText = moment(timestamp).format('dddd');
  } else if (moment(timestamp).add({ months: 1 }).isAfter(moment())) {
    // within the last month
    tsText = moment(timestamp).format('dddd, MMM Do');
  }
  return tsText;
};

export const isTimeConflict = (
  { start: start1, end: end1 },
  { start: start2, end: end2 },
) =>
  moment(start2).isBetween(start1, end1, null, '[)') ||
  moment(end2).isBetween(start1, end1, null, '(]') ||
  moment(start1).isBetween(start2, end2, null, '[)') ||
  moment(end1).isBetween(start2, end2, null, '(]');

export const formatTimeRange = ({
  start,
  end,
}: {
  end: Date;
  start: Date;
}): string => {
  let startTimeFormat = moment(start).minutes() ? 'h:mm - ' : 'h - ';
  if (moment(start).format('A') !== moment(end).format('A')) {
    startTimeFormat = moment(start).minutes() ? 'h:mm A - ' : 'h A - ';
  }
  const endTimeFormat = moment(end).minutes() ? 'h:mm A' : 'h A';

  return (
    moment(start).format(startTimeFormat) + moment(end).format(endTimeFormat)
  );
};

export {
  getFormattedStartEndDate,
  formatDateRange,
  formatDuration,
  formatDate,
  addToDate,
};
