import { store } from 'rfx-core';
import { configure, isObservable } from 'mobx';
import _ from 'lodash';
import moment from 'moment';

import { getChildLogger } from '#/shared/utils/client.logger';
import { Class } from '~/global';
import CarouselCardsStore from '#/shared/stores/carouselCards';
import CarouselCardTemplatesStore from '#/shared/stores/carouselCardTemplates';
import PartnerRoleStatStore from '#/shared/stores/partnerRoleStats';
import PaymentUploaderStore from '#/shared/stores/paymentUploader';

import GCPStore from './stores/gcp';
import UIStore, { UIStoresType } from './stores/ui';
import AppStore from './stores/app';
import AttributesStore from './stores/attributes';
import AuthStore from './stores/auth';
import AuditStore from './stores/audit';
import AuditLogStore from './stores/auditLog';
import AuthManagement from './stores/authManagement';
import CacheStore from './stores/cache';
import DispatchPrefsStore from './stores/dispatchPrefs';
import AddressStore from './stores/address';
import BadgesStore from './stores/badges';
import CampaignStore from './stores/campaigns';
import CannedResponsesStore from './stores/cannedResponses';
import CheckrStore from './stores/checkr';
import CompanyOnboardingsStore from './stores/companyOnboardings';
import UserOnboardingsStore from './stores/userOnboardings';
import CompanyScheduleSummariesStore from './stores/companyScheduleSummaries';
import CompanyStore from './stores/companies';
import WorkerStore from './stores/workers';
import CertStore from './stores/certs';
import NoteStore from './stores/notes';
import ConfigsStore from './stores/configs';
import IndustryStore from './stores/industries';
import InvitesStore from './stores/invites';
import EmployerStore from './stores/employers';
import EmployerUserStore from './stores/employerUsers';
import ExperienceStore from './stores/experiences';
import Notifications from './stores/notifications';
import MessageStore from './stores/messages';
import MessagingCampaignsStore from './stores/messagingCampaigns';
import MessageTagStore from './stores/messageTags';
import Partners from './stores/partners';
import PaymentAccounts from './stores/paymentAccounts';
import PaymentCustomers from './stores/paymentCustomers';
import PaymentTransactions from './stores/paymentTransactions';
import AbilitiesStore from './stores/abilities';
import PersonasStore from './stores/personas';
import PoolStore from './stores/pools';
import PoolPartners from './stores/poolPartners';
import PoolMembers from './stores/poolMembers';
import PrefsStore from './stores/prefs';
import ProductStore from './stores/products';
import ProjectsStore from './stores/projects';
import PartnerAssessmentItemStore from './stores/partnerAssessmentItems';
import PartnerAssessmentResultStore from './stores/partnerAssessmentResults';
import PartnerAssessmentStatusStore from './stores/partnerAssessmentStatuses';
import PayDifferentialStore from './stores/payDifferentials';
import QualsStore from './stores/quals';
import ReviewsStore from './stores/reviews';
import RolesStore from './stores/roles';
import SavedChatFiltersStore from './stores/savedChatFilters';
import SchedulingTemplatesStore from './stores/schedulingTemplates';
import S3Store from './stores/s3';
import SearchStore from './stores/searches';
import ShiftStore from './stores/shifts';
import SkillsStore from './stores/skills';
import StoreStore from './stores/stores';
import StoreListStore from './stores/storeLists';
import ShiftTemplateStore from './stores/shiftTemplates';
import SurveyDefinitionStore from './stores/surveyDefinitions';
import SurveyStore from './stores/surveys';
import SystemStore from './stores/system';
import AssignmentStore from './stores/assignments';
import TagStore from './stores/tags'; // Formerly "Certs Store"
import TagGroupsStore from './stores/tagGroups';
import TaskImageStore from './stores/taskImages';
import ThingStore from './stores/things';
import TrainingStore from './stores/trainings';
import UserStore from './stores/users';
import WorkerLocationStore from './stores/workerLocations';
import ChatChannelStore from './stores/chatChannels';
import UserStatStore from './stores/userStats';
import UserScheduleStore from './stores/userSchedule';
import UserStatHistoryStore from './stores/userStatsHistory';
import RoutingStore from './stores/routing';
import TwilioConversationsStore from './stores/twilioConversations';
import TwilioMessagingStore from './stores/twilioMessaging';
import CheckinStore from './stores/checkins';
import ShiftUploaderStore from './stores/shiftUploader';
import AvailabilityStore from './stores/availability';
import SitesStore from './stores/sites';
import PositionStore from './stores/positions';
import InteractionsStore from './stores/interactions';
import InShiftTaskItemsStore from './stores/inShiftTaskItems';
import InShiftTaskResultsStore from './stores/inShiftTaskResults';
import InShiftTaskTemplatesStore from './stores/inShiftTaskTemplates';
import InShiftTaskTemplateRemapStore from './stores/inShiftTaskTemplateRemap';
import InShiftTasksStore from './stores/inShiftTasks';
import InShiftTaskContainersStore from './stores/inShiftTaskContainers';
import PaymentRulesStore from './stores/paymentRules';
import PaymentBonusesStore from './stores/paymentBonuses';
import PaymentsStore from './stores/payments';
import AgendaJobProgressStore from './stores/agendaJobProgress';
import OpportunitiesStore from './stores/opportunities';
import RecurringScheduleStore from './stores/recurringSchedules';
import ZoneStore from './stores/zones';
import WorkerReferralStore from './stores/workerReferral';
import ReferralBonusesStore from './stores/referralBonus';
import UserLocationStore from './stores/userLocations';
import VideoReviewStore from './stores/videoReviews';
import VideoResponseStore from './stores/videoResponses';
import VideoInterviewQuestionStore from './stores/videoInterviewQuestions';
import NotificationsV2 from './stores/notificationsV2';
import TalkstackStore from './stores/talkstack';
import UserShiftScoreHistoryStore from './stores/userShiftScoreHistory';
import PhoneVerificationsStore from './stores/phoneVerifications';
import TermsOfServiceAcceptanceStore from './stores/tosacceptances';

const log = getChildLogger('stores');

/**
  Enables MobX strict mode globally.
  In strict mode, it is not allowed to
  change any state outside of an action
 */
configure({ enforceActions: 'observed' });

log.debug('Initializing Client Stores');

export type StoreType = {
  abilities: AbilitiesStore;
  addresses: AddressStore;

  agendaJobProgress: AgendaJobProgressStore;
  app: AppStore;
  assignments: AssignmentStore;
  attributes: AttributesStore;
  audit: AuditStore;
  auditLog: AuditLogStore;
  auth: AuthStore;
  authManagement: AuthManagement;
  availability: AvailabilityStore;
  badges: BadgesStore;

  cache: CacheStore;
  campaigns: CampaignStore;

  cannedResponses: CannedResponsesStore;
  carouselCardTemplates: CarouselCardTemplatesStore;
  carouselCards: CarouselCardsStore;
  certs: CertStore;
  chatChannels: ChatChannelStore;
  checkins: CheckinStore;
  checkr: CheckrStore;
  companies: CompanyStore;
  companyOnboardings: CompanyOnboardingsStore;
  companyScheduleSummaries: CompanyScheduleSummariesStore;
  configs: ConfigsStore;
  dispatchPrefs: DispatchPrefsStore;
  employerUsers: EmployerUserStore;
  employers: EmployerStore;
  experiences: ExperienceStore;
  gcp: GCPStore;
  inShiftTaskContainers: InShiftTaskContainersStore;

  inShiftTaskItems: InShiftTaskItemsStore;
  inShiftTaskResults: InShiftTaskResultsStore;
  inShiftTaskTemplateRemap: InShiftTaskTemplateRemapStore;
  inShiftTaskTemplates: InShiftTaskTemplatesStore;
  inShiftTasks: InShiftTasksStore;

  industries: IndustryStore;
  interactions: InteractionsStore;
  invites: InvitesStore;

  messageTags: MessageTagStore;

  messages: MessageStore;

  messagingCampaigns: MessagingCampaignsStore;
  notes: NoteStore;
  notifications: Notifications;
  notificationsV2: NotificationsV2;

  opportunities: OpportunitiesStore;

  partnerAssessmentItems: PartnerAssessmentItemStore;

  partnerAssessmentResults: PartnerAssessmentResultStore;
  partnerAssessmentStatuses: PartnerAssessmentStatusStore;
  partnerRoleStats: PartnerRoleStatStore;
  partners: Partners;
  payDifferentials: PayDifferentialStore;
  paymentAccounts: PaymentAccounts;
  paymentBonuses: PaymentBonusesStore;
  paymentCustomers: PaymentCustomers;
  paymentRules: PaymentRulesStore;
  paymentTransactions: PaymentTransactions;
  paymentUploader: PaymentUploaderStore;
  payments: PaymentsStore;
  personas: PersonasStore;
  phoneVerifications: PhoneVerificationsStore;
  poolMembers: PoolMembers;
  poolPartners: PoolPartners;
  pools: PoolStore;
  positions: PositionStore;
  prefs: PrefsStore;
  products: ProductStore;
  projects: ProjectsStore;

  quals: QualsStore;

  recurringSchedules: RecurringScheduleStore;
  referralBonuses: ReferralBonusesStore;
  reviews: ReviewsStore;
  roles: RolesStore;
  routing: RoutingStore;
  s3: S3Store;
  savedChatFilters: SavedChatFiltersStore;

  schedulingTemplates: SchedulingTemplatesStore;
  searches: SearchStore;
  shiftTemplates: ShiftTemplateStore;
  shiftUploader: ShiftUploaderStore;
  shifts: ShiftStore;
  sites: SitesStore;
  skills: SkillsStore;
  storeLists: StoreListStore;
  stores: StoreStore;
  surveyDefinitions: SurveyDefinitionStore;
  surveys: SurveyStore;
  system: SystemStore;
  tagGroups: TagGroupsStore;

  tags: TagStore;
  talkstack: TalkstackStore;
  taskImages: TaskImageStore;
  things: ThingStore;
  tosacceptances: TermsOfServiceAcceptanceStore;
  trainings: TrainingStore;
  twilioConversations: TwilioConversationsStore;
  twilioMessaging: TwilioMessagingStore;
  ui: UIStore & UIStoresType;

  userLocations: UserLocationStore;
  userOnboardings: UserOnboardingsStore;
  userSchedule: UserScheduleStore;
  userShiftScoreHistory: UserShiftScoreHistoryStore;
  userStats: UserStatStore;
  userStatsHistory: UserStatHistoryStore;
  users: UserStore;

  videoInterviewQuestions: VideoInterviewQuestionStore;
  videoResponses: VideoResponseStore;
  videoReviews: VideoReviewStore;

  workerLocations: WorkerLocationStore;
  workerReferral: WorkerReferralStore;
  workers: WorkerStore;

  zones: ZoneStore;
};

/**
 * This object stores the class objects for each Domain Store, which is then fed into
 * the rfx-core's `@extends` method to instantiate and initialize all store instances.
 */
const storeClasses: Record<keyof StoreType, Class> = {
  abilities: AbilitiesStore,
  addresses: AddressStore,

  agendaJobProgress: AgendaJobProgressStore,
  app: AppStore,
  assignments: AssignmentStore,
  attributes: AttributesStore,
  audit: AuditStore,
  auditLog: AuditLogStore,
  auth: AuthStore,
  authManagement: AuthManagement,
  availability: AvailabilityStore,
  badges: BadgesStore,

  cache: CacheStore,
  campaigns: CampaignStore,

  cannedResponses: CannedResponsesStore,
  carouselCardTemplates: CarouselCardTemplatesStore,
  carouselCards: CarouselCardsStore,
  certs: CertStore,
  chatChannels: ChatChannelStore,
  checkins: CheckinStore,
  checkr: CheckrStore,
  companies: CompanyStore,
  companyOnboardings: CompanyOnboardingsStore,
  companyScheduleSummaries: CompanyScheduleSummariesStore,
  configs: ConfigsStore,
  dispatchPrefs: DispatchPrefsStore,
  employerUsers: EmployerUserStore,
  employers: EmployerStore,

  experiences: ExperienceStore,

  gcp: GCPStore,

  inShiftTaskContainers: InShiftTaskContainersStore,

  inShiftTaskItems: InShiftTaskItemsStore,
  inShiftTaskResults: InShiftTaskResultsStore,
  inShiftTaskTemplateRemap: InShiftTaskTemplateRemapStore,
  inShiftTaskTemplates: InShiftTaskTemplatesStore,
  inShiftTasks: InShiftTasksStore,

  industries: IndustryStore,
  interactions: InteractionsStore,
  invites: InvitesStore,

  messageTags: MessageTagStore,

  messages: MessageStore,
  messagingCampaigns: MessagingCampaignsStore,
  notes: NoteStore,
  notifications: Notifications,
  notificationsV2: NotificationsV2,

  opportunities: OpportunitiesStore,

  partnerAssessmentItems: PartnerAssessmentItemStore,

  partnerAssessmentResults: PartnerAssessmentResultStore,
  partnerAssessmentStatuses: PartnerAssessmentStatusStore,
  partnerRoleStats: PartnerRoleStatStore,
  partners: Partners,
  payDifferentials: PayDifferentialStore,
  paymentAccounts: PaymentAccounts,
  paymentBonuses: PaymentBonusesStore,
  paymentCustomers: PaymentCustomers,
  paymentRules: PaymentRulesStore,
  paymentTransactions: PaymentTransactions,
  paymentUploader: PaymentUploaderStore,
  payments: PaymentsStore,
  personas: PersonasStore,
  phoneVerifications: PhoneVerificationsStore,
  poolMembers: PoolMembers,
  poolPartners: PoolPartners,
  pools: PoolStore,
  positions: PositionStore,
  prefs: PrefsStore,
  products: ProductStore,
  projects: ProjectsStore,
  quals: QualsStore,

  recurringSchedules: RecurringScheduleStore,
  referralBonuses: ReferralBonusesStore,
  reviews: ReviewsStore,
  roles: RolesStore,
  routing: RoutingStore,

  s3: S3Store,
  savedChatFilters: SavedChatFiltersStore,
  schedulingTemplates: SchedulingTemplatesStore,
  searches: SearchStore,
  shiftTemplates: ShiftTemplateStore,
  shiftUploader: ShiftUploaderStore,
  shifts: ShiftStore,
  sites: SitesStore,
  skills: SkillsStore,
  storeLists: StoreListStore,
  stores: StoreStore,
  surveyDefinitions: SurveyDefinitionStore,
  surveys: SurveyStore,
  system: SystemStore,

  tagGroups: TagGroupsStore,
  tags: TagStore,
  talkstack: TalkstackStore,
  taskImages: TaskImageStore,
  things: ThingStore,
  tosacceptances: TermsOfServiceAcceptanceStore,
  trainings: TrainingStore,
  twilioConversations: TwilioConversationsStore,
  twilioMessaging: TwilioMessagingStore,

  ui: UIStore,
  userLocations: UserLocationStore,
  userOnboardings: UserOnboardingsStore,
  userSchedule: UserScheduleStore,
  userShiftScoreHistory: UserShiftScoreHistoryStore,
  userStats: UserStatStore,
  userStatsHistory: UserStatHistoryStore,
  users: UserStore,

  videoInterviewQuestions: VideoInterviewQuestionStore,
  videoResponses: VideoResponseStore,
  videoReviews: VideoReviewStore,

  workerLocations: WorkerLocationStore,
  workerReferral: WorkerReferralStore,
  workers: WorkerStore,

  zones: ZoneStore,
};

export const stores: StoreType & {
  $imports: typeof storeClasses;
  $stores: StoreType;
} = store.setup(storeClasses);

export type StoreName = keyof Omit<StoreType, 'ui'>;

if (process.env.NODE_ENV === 'development') {
  if (global.TYPE === 'CLIENT') {
    // @ts-expect-error Dev-only global registration
    window.stores = stores.$stores;
    window.moment = moment;
    window.isObservable = isObservable;
  } else if (global.TYPE === 'SERVER') {
    // @ts-expect-error Dev-only global registration
    global.stores = stores.$stores;
  }
}

log.debug('Initialized Stores (%d registered)', _.size(stores));

stores.displayName = 'stores';

export default stores;
