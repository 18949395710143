import type { IPhoneVerification } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from '#/shared/stores/_baseStore';

export default class PhoneVerificationsStore extends BaseStore<IPhoneVerification> {
  constructor({
    serviceName = 'phoneVerifications',
    title = 'phoneVerification',
  } = {}) {
    super({
      baseItem: PhoneVerificationsStore.BASE_ITEM,
      serviceName,
      title,
    });
    return this;
  }

  static BASE_ITEM: IPhoneVerification = {
    ...BaseStore.BASE_ITEM,
    deviceId: null,
    isVerified: false,
    lastSentAt: null,
    numberOfTimesCodeWasSent: 0,
    phoneNumber: null,
    userId: null,
    verifyExpires: null,
    verifyShortToken: null,
    verifyToken: null,
  };
}
