import type {
  CompanyModules,
  ICompany,
  IUser,
} from '@shiftsmartinc/shiftsmart-types';

import React from 'react';
import { observable, action, computed } from 'mobx';
import { dispatch } from 'rfx-core';
import _ from 'lodash';
import { IconProps } from 'semantic-ui-react';

import { app } from '#/shared/app';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('stores.ui.appNav');

/**
 * # AppNav UI Store
 * Stores data pertaining to which menu items are shown to the user.
 */
export default class AppNav {
  /** Reserved for Dev-Use only; useful for showing _ALL_ nav items for testing */
  showAll = false;

  @observable
  isUserMenuOpen = false;

  init() {
    app().on('abilities:loaded', this.reloadMenuItems);
  }

  @action.bound
  toggleUserMenu(val = !this.isUserMenuOpen) {
    this.isUserMenuOpen = val;
  }

  /**
   * ## menuItemFilter
   * @description Looks at the attributes of the menu item and and decides
   * whether or not it should be shown to the user. This method first checks
   * if the user has CASL "access" to the route (if a `subject` is defined),
   * and then iterates through the other options.
   *
   * #### Logic
   * A menu item will be shown if all of the following are true:
   * 1. If `subject` is defined and `auth.can('access', subject)` is true
   * 2. The `visible` method or value (if present) evalutes to true
   * 3. The `shouldHide` method or value (if present) evalueates to false
   * 4. If no `subject`, `visible` or `shouldHide`, check if the `module` is enabled on the company
   *
   * @returns boolean;
   */
  menuItemFilter({
    menuItem,
    user,
    company,
    childRouteItem,
  }: {
    childRouteItem?: NavItemDefinition['childRoutes'][0];
    company: ICompany;
    menuItem: NavItemDefinition;
    user: IUser;
  }) {
    let hide = false;
    let show = true;
    const fields = childRouteItem
      ? childRouteItem.fields
      : (menuItem as CaslNavItem)?.fields;
    if (
      !!menuItem.subject &&
      dispatch('abilities.cannot', 'access', menuItem.subject, fields)
    ) {
      log.silly(`User cannot access the ${menuItem.route} route`);
      return false;
    }

    if (_.isFunction(menuItem.visible)) {
      show = menuItem.visible({ company, user });
    } else if (_.has(menuItem, 'visible')) {
      show = !!menuItem.visible;
    }

    if (_.isFunction(menuItem.shouldHide)) {
      hide = menuItem.shouldHide({ company, user });
    } else if (_.has(menuItem, 'shouldHide')) {
      hide = !!menuItem.shouldHide;
    }

    if (
      _.isEmpty(menuItem.subject) &&
      _.isNil(menuItem.visible) &&
      _.isNil(menuItem.shouldHide)
    ) {
      show = !!company?.modules?.[(menuItem as LegacyNavItem)?.module];
    }

    const retval = !hide && show;

    // Leave in place for future debugging [PDF 2018-03-19]
    log.silly(
      `${retval ? 'Showing' : 'Hiding'}
       "${menuItem.title}" Route`,
      {
        hide,
        menuItem,
        show,
        user,
      },
    );

    return retval;
  }

  /**
   * ## reloadMenuItems
   * @description given the user/company pair, recalculates the menu items available to
   * the logged in user. Primarily driven by the `abilities:loaded` event to rebuild the
   * lists.
   * @param {*} param0
   */

  @action.bound
  reloadMenuItems({ company, user }) {
    log
      .getChildLogger('reloadMenuItems')
      .debug(
        `Reloading Menu Items for user/company context ${user?.displayName} : ${company?.name}`,
      );
    this._linkItems.replace(
      this.getLinkItems({ company, linkItems: this.LINK_ITEMS, user }),
    );
    this._adminLinkItems.replace(
      this.getAdminLinkItems({
        company,
        linkItems: this.ADMIN_LINK_ITEMS,
        user,
      }),
    );
  }

  _linkItems = observable.array([]);

  @computed
  get linkItems() {
    return this._linkItems;
  }

  @action.bound
  getLinkItems({ linkItems, user, company }) {
    log
      .getChildLogger('getLinkItems')
      .silly(
        `recomputing link items for ${user?.displayName} : ${company?.name}`,
      );

    const linkGroups = _(linkItems)
      .reject({ module: 'admin' })
      .map(({ key, items }) => {
        const groupItems = /^admin$/i.test(items.module)
          ? []
          : _(items)
              .reject({ module: 'admin' })
              .filter((igIndex) => {
                log.debug('looking at item: ', igIndex);
                if (this.showAll) {
                  return true;
                }
                if (
                  this.menuItemFilter({
                    company,
                    menuItem: igIndex,
                    user,
                  })
                ) {
                  igIndex.childRoutes = igIndex?.childRoutes?.filter(
                    (childRouteItem) => {
                      log.debug('looking at child item: ', childRouteItem);
                      return this.menuItemFilter({
                        childRouteItem,
                        company,
                        menuItem: igIndex,
                        user,
                      });
                    },
                  );
                  return igIndex;
                }
                return false;
              })
              .value();

        return { items: groupItems, key };
      })
      .value();

    log.debug(
      'Returning %d link groups (of %d available)',
      _.size(linkGroups),
      _.size(linkItems),
      { linkGroups },
    );

    return linkGroups;
  }

  @action.bound
  getAdminLinkItems({ linkItems, user, company }) {
    log
      .getChildLogger('getAdminLinkItems')
      .silly(
        `recomputing link items for ${user?.displayName} : ${company?.name}`,
      );
    return _.filter(linkItems, (menuItem) =>
      this.menuItemFilter({
        company,
        menuItem,
        user,
      }),
    );
  }

  _adminLinkItems = observable.array([]);

  @computed
  get adminLinks() {
    return this._adminLinkItems;
  }

  get LINK_ITEMS(): Array<NavItemGroup> {
    return [
      {
        items: [
          {
            icon: 'ssm_icon:HomeIcon',
            image: '/static/img/nav/dashboard.svg',
            module: 'core',
            route: '/',
            routeMatch: '^/$',
            subject: 'dashboard',
            title: 'Dashboard',
          },
          {
            icon: 'ssm_icon:MapIcon',
            image: '/static/img/nav/map.svg',
            route: '/map',
            routeMatch: '^/?map[^d]?',
            subject: 'map',
            title: 'Map',
          },
          {
            icon: 'check circle outline',
            module: 'onboarding',
            route: '/onboarding',
            routeMatch: '^/?onboarding',
            subject: 'onboarding',
            title: 'Onboarding flow',
          },
        ],
        key: 'landing',
      },
      {
        items: [
          {
            icon: 'calendar alternate outline',
            module: 'recurringSchedules',
            onClick: () =>
              dispatch('routing.goto', { state: 'recurringSchedules' }),
            route: '/recurringSchedules',
            routeMatch: '^/?recurringSchedules',
            subject: 'recurringSchedules',
            title: 'Recurring Schedules',
          },
          {
            icon: 'ssm_icon:ShiftIcon',
            image: '/static/img/nav/shifts.svg',
            module: 'shifts',
            onClick: () =>
              dispatch('ui.snackBar.open', 'clicked shifts') ||
              dispatch('routing.goto', { state: 'shifts' }),
            route: '/shifts',
            routeMatch: '^/?shifts|pts',
            subject: 'shifts',
            title: 'Shifts',
          },
          {
            icon: 'tasks',
            module: 'inShiftTasks',
            route: '/inshifttasks',
            routeMatch: '^/?inshifttasks',
            shouldHide: ({ company }) => !company?.modules?.inShiftTasks,
            subject: 'inShiftTasks',
            title: 'In Shift Tasks',
          },
          {
            icon: 'envelope open outline',
            module: 'dispatchPrefs',
            route: '/dispatchPrefs',
            routeMatch: '^/?dispatchPrefs',
            subject: '',
            title: 'Dispatch Prefs',
          },
          {
            icon: 'ssm_icon:PartnerIcon',
            image: '/static/img/nav/partners.svg',
            module: 'core',
            route: '/partners',
            routeMatch: '^/?partners',
            subject: 'partners',
            title: 'Partners',
          },
          {
            icon: 'ssm_icon:PoolIcon',
            image: '/static/img/nav/pools.svg',
            module: 'core',
            route: '/pools',
            routeMatch: '^/?pools',
            subject: 'pools',
            title: 'Pools',
          },
          {
            icon: 'list alternate outline',
            route: '/videoInterviews',
            routeMatch: '^/?videoInterviews',
            subject: 'videoReview',
            title: 'Video Interviews',
            visible: ({ user, company }) =>
              !!user?.companyStatus?.find(
                (status) =>
                  status.roles?.includes('video-review') &&
                  status.company === company?.uuid,
              ),
          },
          {
            icon: 'map marker alternate',
            route: '/locations',
            routeMatch: '^/?locations',
            shouldHide: ({ company }) =>
              !company?.modules?.shifts || !dispatch('auth.hasSavedLocations'),
            subject: 'shifts',
            title: 'Locations',
          },
        ],
        key: 'core',
      },
      {
        items: [
          {
            childRoutes: [
              {
                fields: 'campaigns',
                icon: 'bullhorn',
                route: '/retail/campaigns',
                routeMatch: '^/?retail/campaigns',
                title: 'Campaigns',
              },
              {
                fields: 'stores',
                icon: 'warehouse',
                route: '/retail/stores',
                routeMatch: '^/?retail/stores',
                title: 'Stores',
              },
              {
                fields: 'storeLists',
                icon: 'shopping bag',
                route: '/retail/storeLists',
                routeMatch: '^/?retail/storeLists',
                title: 'Store Lists',
              },
              {
                fields: 'surveys',
                icon: 'clipboard outline',
                route: '/retail/surveys',
                routeMatch: '^/?retail/surveys',
                title: 'Surveys',
              },
              {
                fields: 'products',
                icon: 'barcode',
                route: '/retail/products',
                routeMatch: '^/?retail/products',
                title: 'Products',
              },
              {
                fields: 'trainings',
                icon: 'clipboard check',
                route: '/retail/trainings',
                routeMatch: '^/?retail/trainings',
                title: 'Trainings',
              },
              {
                fields: 'campaigns.work',
                icon: 'briefcase',
                route: '/retail/campaigns/work',
                routeMatch: '^/?retail/campaigns/work',
                title: 'Work',
              },
              {
                fields: 'campaigns.reports',
                icon: 'file alternate outline',
                route: '/retail/campaigns/reports',
                routeMatch: '^/?retail/campaigns/reports',
                title: 'Reports',
              },
              {
                fields: 'campaigns.approve',
                icon: 'cart plus',
                route: '/retail/campaigns/approve',
                routeMatch: '^/?retail/campaigns/approve',
                title: 'Approve/Reject Shops',
              },
            ],
            icon: 'cart',
            image: '/static/img/nav/retail.svg',
            module: 'retail',
            route: '/retail',
            routeMatch: '^/?retail',
            shouldHide: ({ company }) => !company?.modules?.retail,
            subject: 'retail',
            title: 'Retail',
          },
          {
            icon: 'cart',
            module: 'inspections',
            route: '/inspections',
            routeMatch: '^/?inspections',
            shouldHide: ({ company }) =>
              company.uuid !== '9b66774e-406f-4c7d-b2c6-b95a994b5984',
            subject: 'inspections',
            title: 'Inspections',
          },
        ],
        key: 'retail',
      },
      {
        items: [
          {
            icon: 'list ol',
            iconActive: 'list ol',
            module: 'trainings',
            route: '/surveys',
            routeMatch: '^/?surveys',
            shouldHide: ({ company }) => !!company?.modules?.retail,
            subject: 'surveys',
            title: 'Surveys',
          },
          {
            icon: 'clipboard check',
            module: 'trainings',
            route: '/trainings',
            routeMatch: '^/?trainings',
            shouldHide: ({ company }) => !!company?.modules?.retail,
            subject: 'trainings',
            title: 'Trainings',
          },
        ],
        key: 'training',
      },
    ];
  }

  get ADMIN_LINK_ITEMS() {
    return <NavItemDefinition[]>[
      {
        icon: 'building',
        key: 'company',
        module: 'admin',
        route: '/company',
        routeMatch: '^/?company',
        title: 'Company',
        visible: ({ company }) =>
          dispatch('abilities.can', 'update', company, 'settings'),
      },
      {
        icon: 'settings',
        key: 'admin',
        module: 'admin',
        route: '/admin',
        routeMatch: '^/?admin',
        subject: 'admin',
        title: 'Admin',
        visible: ({ user }) => !!user.isAdmin,
      },
    ];
  }
}

export type NavItemGroup = {
  /** List of Nav Item definition objects */
  items: Array<NavItemDefinition>;
  /** A unique key */
  key: string;
};

export type NavItemDefinition = (
  | LegacyNavItem // FIXME: This restriction is not being properly applied
  | RequireAtLeastOne<
      CaslNavItem & NavItemVisibility,
      'subject' | 'visible' | 'shouldHide'
    >
) &
  NavItemBase;

type CaslNavItem = {
  /**
   * The CASL "fields" for the relevant "subject" defined above, will be passed
   * to `abilities.can('access', subject, fields)` to determine if the route is accessible.
   *
   * **NOTE** The `subject` property _must_ be defined for this to work.
   *
   * Most commonly used to control access to sub-routes, eg: can('access', 'retail', 'campaigns')
   */
  fields?: string;
  /**
   * The CASL "subject" for the relative "access" grant, will be passed
   * to `abilities.can('access', subject)` to determine if the route is accessible
   */
  subject: keyof CompanyModules | string;
};

type NavItemVisibility = {
  /**
   * Function which, if returns `true`, will hide the nav item. If defined alongside the
   * `shouldHide` param, it will be AND-ed as `show && !hide` to determine visibility
   */
  shouldHide?:
    | boolean
    | ((arg0: { company: ICompany; user: IUser }) => boolean);

  /**
   * Function which, if returns `false`, will hide the nav item. If defined alongside the
   * `shouldHide` param, it will be AND-ed as `show && !hide` to determine visibility
   */
  visible?: boolean | ((arg0: { company: ICompany; user: IUser }) => boolean);
};

type LegacyNavItem = {
  /**
   * Define based on whether a specific module is enabled for a company. This field will
   * **only** be applied if all of `subject`, `visible` and `shouldHide` are all not defined.
   * @deprecated prefer the `subject` field to take advantage of CASL permissions
   */
  module: keyof CompanyModules | 'admin';

  shouldHide?: never;
  subject?: never | '';
  visible?: never;
};

type NavItemBase = {
  childRoutes?: Array<
    Omit<NavItemDefinition, 'subject'> & { subject?: never } & {
      fields?: CaslNavItem['fields'];
    }
  >;
  // eg: 'id card',
  /** Displays a small badge on the icon, useful for displaying "number of new messages" concepts */
  count?: number;
  // 'Customers',
  icon:
    | IconProps['name']
    | `ssm_icon:${string}`
    | ((unknown) => React.ReactElement)
    | React.ReactElement;
  // eg: '/static/img/nav/chat.svg'
  iconActive?: IconProps['name'] | React.ReactElement | string;
  // eg: 'id card outline',
  image?: string;
  /** A unique key within the current nesting level */
  key?: string;
  /** @deprecated This field is not used in NavBar.tsx as far as I can tell */
  onClick?: () => unknown;

  // '^/?customers',
  route: string;

  routeMatch?: string | RegExp;

  // '/customers',
  title: string;
};

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];
