import type {
  IAssignment,
  ICompany,
  IUser,
} from '@shiftsmartinc/shiftsmart-types';

import { autorun, action, computed, observable, set, runInAction } from 'mobx';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import isUUID from 'uuid-validate';
import _ from 'lodash';
import palette from 'google-palette';
import { Location } from 'react-router';
import { Query } from '@feathersjs/feathers/lib/declarations';

import hexRgb from '#/utils/hexRgb';
import { getChildLogger } from '#/shared/utils/client.logger';

const log = getChildLogger('stores.ui.admin');

export default class Admin {
  @observable
  activeItem = '';

  @observable
  noClear = false;

  selected = {};

  @observable
  company: Partial<ICompany> = {};

  @observable
  timeInterval = '1M';

  @observable
  dashboard = {
    fillRateThreshold: null,
    laborCostsThreshold: null,
  };

  @observable
  chartRefs: {
    actionsChart?: any;
    assignmentChart?: any;
    shiftChart?: any;
    signupChart?: any;
  } = {
    actionsChart: null,
  };

  @observable
  showChart = {
    assignments: false,
    pts: false,
  };

  @observable
  selectedUserCert = [];

  @observable
  paymentHistoryCustomCopy = '';

  @observable
  expectedPaymentCustomCopy = '';
  user: Partial<IUser> = {};

  @action
  async setSelectedUserCert(user) {
    const userTagsAndPositions = user.certs
      .filter(
        (cert) =>
          /position|qual/i.test(cert.certType) && !_.isEmpty(cert.companies),
      )
      .map((cert) => _.extend(cert, { companyId: _.first(cert.companies) }))
      .reduce((acc, obj) => {
        const key = obj.companyId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

    const formattedTagsAndPositions = [];
    // eslint-disable-next-line
    for (let key in userTagsAndPositions) {
      // eslint-disable-next-line no-await-in-loop
      const company = await dispatch('companies.get', key);
      formattedTagsAndPositions.push({
        certs: userTagsAndPositions[key],
        companyId: key,
        companyName: company.name,
      });
    }
    runInAction(() => {
      this.selectedUserCert = formattedTagsAndPositions;
    });
  }

  @action
  setChartRef({ ref, name }) {
    if (_.get(ref, 'chartInstance')) {
      set(this.chartRefs, name, ref.chartInstance);
      log.debug(`Set ${name} chart ref`, { ref: this.chartRefs[name] });
    }
  }

  @action
  toggleChart({ name }) {
    set(this.showChart, name, !this.showChart[name]);
  }

  @action
  toggleMap() {
    set(this.partnerDetails, 'showMap', !this.partnerDetails.showMap);
  }

  @action
  setNoClear(val) {
    this.noClear = val;
  }

  @action
  setTimeInterval(value) {
    this.timeInterval = value;

    this.fetchDashboardData({ reset: true });
  }

  @computed
  get selectedId() {
    return _.get(this.selected, 'uuid', null);
  }

  @action
  setSelected(value = {}) {
    this.selected = value;
  }

  setPageHeader({
    selected,
    onDeselect = () => dispatch('ui.admin.setActiveItem', ''),
    actions,
  }) {
    dispatch('ui.setHeader', {
      actions,
      menuSize: 'tiny',
      onDeselect,
      selected,
      title: 'Admin',
    });
  }

  @computed
  get interval() {
    try {
      return [_.toNumber(this.timeInterval[0]), this.timeInterval[1]];
    } catch (err) {
      log.error(`Unparsable Time Interval: "${this.timeInterval}"`);
      return [1, 'M'];
    }
  }

  @action
  setActiveItem(item) {
    this.activeItem = item;

    const match = !item || item === 'admin' ? '/admin/?$' : `/admin/${item}`;
    const path = !item ? '/admin' : `/admin/${item}`;

    if (!global.IS_SSR && !RegExp(match, 'i').test(window.location.href)) {
      log.debug(`Routing to path "${path}"`);
      dispatch('routing.push', path);
    }

    return this.activeItem;
  }

  getActiveItem() {
    return this.activeItem;
  }

  @action
  routeToActiveItem({ location }: { location: Location }) {
    const { pathname, hash } = location;

    const urlItem = _(pathname.split('/'))
      .reject((path) => isUUID(path))
      .last();

    if (urlItem !== this.activeItem && isValidAdminTab(urlItem)) {
      this.setActiveItem(urlItem);
    }
    if (/^admin$/i.test(urlItem)) {
      this.setActiveItem('');
    }

    if (!_.isEmpty(hash)) {
      const loc = hash.replace('#', '');
      if (loc !== this.activeItem && isValidAdminTab(loc)) {
        this.setActiveItem(loc);
      }
    }

    return this.activeItem;
  }

  @observable
  summaryData = {
    assignments: [],
    cleared: [],
    employers: { active: -1 },
    labels: [],
    partners: { active: -1 },
    ptss: [],
    shifts: { active: -1, completed: -1, total: -1 },
    signups: [],
    surveys: { active: -1, completed: -1 },
    tasks: { active: -1, completed: -1 },
    weeks: [],
  };

  @observable
  chartData = {
    assignmentChartOptions: {},
    shiftChartOptions: {},
    signupChartOptions: {},
  };

  init() {
    autorun(() => log.debug('sumaryData Change: ', this.summaryData));
  }

  @action
  selectCompany(co) {
    if (_.isEmpty(co)) {
      return this.clearCompany();
    }
    if (!_.get(co, 'uuid')) {
      return Promise.reject(new Error('Must select full company object'));
    }

    this.company = co;

    return this.company;
  }

  @action
  clearCompany() {
    this.company = {};
    return this.company;
  }

  @computed
  get dateRange() {
    const dateRange = [];

    const startDate = moment().subtract(...this.interval);
    const endDate = moment();

    while (startDate.isSameOrBefore(endDate)) {
      dateRange.push({
        date: startDate.date(),
        doy: startDate.dayOfYear(),
        formatted: startDate.format('L'),
        month: startDate.month(),
        year: startDate.year(),
      });
      startDate.add({ day: 1 });
    }

    return dateRange;
  }

  @action
  async fetchDashboardData({ reset = false } = {}) {
    set(this.summaryData, 'labels', this.dateRange);

    try {
      dispatch('workers.find', {
        query: {
          $client: {
            disableDocumentCount: true,
          },
          $sort: { createdAt: -1 },
          companies: this.company.uuid || undefined,
        },
      });

      await this.fetchSignupsByWeek();
      this.getSignupChartOptions({ reset });

      // this.fetchPTSByWeek().then(() => this.getShiftChartOptions({ reset }));

      // this.fetchAssignmentSummaryData().then(() =>
      //   this.getAssignmentChartOptions({ reset }),
      // );

      this.initAndSet({
        getter: 'partners.getCount',
        queryData: {
          companies: this.company.uuid || undefined,
          isDeleted: false,
        },
        setter: 'partners.active',
        target: this.summaryData,
      });

      this.initAndSet({
        getter: 'employerUsers.getCount',
        queryData: {
          companies: this.company.uuid || undefined,
          isDeleted: false,
          password: { $ne: null },
          roles: 'employer',
        },
        setter: 'employers.active',
        target: this.summaryData,
      });

      // this.initAndSet({
      //   getter: 'shifts.getCount',
      //   queryData: {
      //     status: 'Active',
      //     company: this.company.uuid || undefined,
      //   },
      //   target: this.summaryData,
      //   setter: 'shifts.active',
      // });

      // this.initAndSet({
      //   getter: 'shifts.getCount',
      //   queryData: {
      //     status: { $ne: 'Draft' },
      //     company: this.company.uuid || undefined,
      //   },
      //   target: this.summaryData,
      //   setter: 'shifts.total',
      // });

      // this.initAndSet({
      //   getter: 'shifts.getCount',
      //   queryData: {
      //     status: 'Completed',
      //     company: this.company.uuid || undefined,
      //   },
      //   target: this.summaryData,
      //   setter: 'shifts.completed',
      // });

      // this.initAndSet({
      //   getter: 'surveys.getCount',
      //   queryData: {
      //     status: 'Active',
      //     company: this.company.uuid || undefined,
      //   },
      //   target: this.summaryData,
      //   setter: 'surveys.active',
      // });

      // this.initAndSet({
      //   getter: 'surveys.getCount',
      //   queryData: {
      //     status: 'Completed',
      //     company: this.company.uuid || undefined,
      //   },
      //   target: this.summaryData,
      //   setter: 'surveys.completed',
      // });
    } catch (err) {
      log.error('Failed to fetch Dashboard Data', err);
    }
  }

  @action
  initAndSet({ getter, queryData, target, setter, init = -1 }) {
    _.set(target, setter, init);

    return dispatch(getter, queryData)
      .then(
        action((retval) => {
          _.set(target, setter, retval);
        }),
      )
      .catch(
        action((err) => {
          log.error(`Failed to set ${setter} due to error`, err);
          _.set(target, setter, '-');
        }),
      );
  }

  @action
  fetchSignupsByWeek() {
    const tzOffset = 7 * 60 * 60 * 1000;

    const $match: Query = {
      createdAt: {
        $gte: moment()
          .subtract(...this.interval)
          .toDate(),
      },
    };

    if (this.company.uuid) {
      $match.companies = this.company.uuid;
    }

    const pipeline = [
      { $match },
      {
        $group: {
          _id: {
            day: { $dayOfMonth: [{ $subtract: ['$createdAt', tzOffset] }] },
            doy: { $dayOfYear: [{ $subtract: ['$createdAt', tzOffset] }] },
            month: { $month: [{ $subtract: ['$createdAt', tzOffset] }] },
            week: { $week: [{ $subtract: ['$createdAt', tzOffset] }] },
            year: { $year: [{ $subtract: ['$createdAt', tzOffset] }] },
          },
          count: { $sum: 1 },
        },
      },
    ];

    return dispatch('partners.runQuery', { _aggregate: pipeline }).then(
      action((res) => {
        set(
          this.summaryData,
          'signups',
          _.orderBy(
            res,
            ['id.year', 'id.month', 'id.day'],
            ['asc', 'asc', 'asc'],
          ),
        );
      }),
    );
  }

  @action
  fetchClearedProfiles() {
    const tzOffset = 7 * 60 * 60 * 1000;

    const $match: Query = {
      'statusLog.prop': 'needsReview', //not sure here
      'statusLog.time': {
        $gte: moment()
          .subtract(...this.interval)
          .toDate(),
      },
      'statusLog.val': false,
      updatedAt: {
        $gte: moment()
          .subtract(...this.interval)
          .toDate(),
      },
    };

    if (this.company.uuid) {
      $match.company = this.company.uuid;
    }

    const pipeline = [
      {
        $unwind: '$statusLog',
      },
      {
        $match,
      },
      {
        $group: {
          _id: {
            day: {
              $dayOfMonth: [{ $subtract: ['$statusLog.time', tzOffset] }],
            },
            doy: { $dayOfYear: [{ $subtract: ['$statusLog.time', tzOffset] }] },
            month: { $month: [{ $subtract: ['$statusLog.time', tzOffset] }] },
            week: { $week: [{ $subtract: ['$statusLog.time', tzOffset] }] },
            year: { $year: [{ $subtract: ['$statusLog.time', tzOffset] }] },
          },
          count: { $sum: 1 },
        },
      },
    ];

    dispatch('partners.runQuery', { _aggregate: pipeline }).then(
      action((res) => {
        log.debug('Got Cleared Profile Aggregation results: ', { res });
        set(
          this.summaryData,
          'cleared',
          _.orderBy(res, ['id.year', 'id.doy'], ['asc', 'asc', 'asc']),
        );
      }),
    );
  }

  @action
  fetchPTSByWeek() {
    const tzOffset = 7 * 60 * 60 * 1000;

    const $match: Query = {
      __t: { $in: ['shift', undefined] },
      start: {
        $gte: moment()
          .subtract(...this.interval)
          .toDate(),
      },
    };

    if (this.company.uuid) {
      $match.company = this.company.uuid;
    }

    const pipeline = [
      {
        $match,
      },
      {
        $group: {
          _id: {
            day: {
              $dayOfMonth: [
                {
                  $subtract: [{ $ifNull: ['$start', '$createdAt'] }, tzOffset],
                },
              ],
            },
            doy: {
              $dayOfYear: [
                {
                  $subtract: [{ $ifNull: ['$start', '$createdAt'] }, tzOffset],
                },
              ],
            },
            month: {
              $month: [
                {
                  $subtract: [{ $ifNull: ['$start', '$createdAt'] }, tzOffset],
                },
              ],
            },
            type: '$__t',
            week: { $week: [{ $subtract: ['$createdAt', tzOffset] }] },
            year: {
              $year: [
                {
                  $subtract: [{ $ifNull: ['$start', '$createdAt'] }, tzOffset],
                },
              ],
            },
          },
          count: { $sum: 1 },
        },
      },
    ];

    return dispatch('things.runQuery', { _aggregate: pipeline }).then(
      action((res) => {
        set(
          this.summaryData,
          'ptss',
          _.orderBy(
            res,
            ['id.year', 'id.month', 'id.day'],
            ['asc', 'asc', 'asc'],
          ),
        );
      }),
    );
  }

  @action
  fetchAssignmentSummaryData() {
    const tzOffset = 7 * 60 * 60 * 1000;

    const $match: Query = {
      completedAt: {
        $gte: moment()
          .subtract(...this.interval)
          .toDate(),
      },
      createdAt: {
        $gte: moment()
          .subtract(...this.interval)
          .toDate(),
      },
    };

    if (this.company.uuid) {
      $match.company = this.company.uuid;
    }

    const pipeline = [
      {
        $match,
      },
      {
        $group: {
          _id: {
            day: { $dayOfMonth: [{ $subtract: ['$completedAt', tzOffset] }] },
            doy: { $dayOfYear: [{ $subtract: ['$completedAt', tzOffset] }] },
            month: { $month: [{ $subtract: ['$completedAt', tzOffset] }] },
            type: '$dataType',
            week: { $week: [{ $subtract: ['$createdAt', tzOffset] }] },
            year: { $year: [{ $subtract: ['$completedAt', tzOffset] }] },
          },
          count: { $sum: 1 },
        },
      },
    ];

    return dispatch('assignments.runQuery', { _aggregate: pipeline }).then(
      action((res) => {
        set(
          this.summaryData,
          'assignments',
          _.orderBy(
            res,
            ['id.year', 'id.month', 'id.day'],
            ['asc', 'asc', 'asc'],
          ),
        );
      }),
    );
  }

  /* Admin Employer User Details */
  @observable
  employerDetails = {
    activeTab: 'summary',
  };

  @computed
  get employerDetailsTabs() {
    return [
      {
        icon: { name: 'chevron left' },
        index: 0,
        key: 'back',
        onSelect: () => {
          dispatch('ui.admin.setNoClear', true);
          dispatch('routing.pop');
          this.setEmployerDetailsTab('summary');
        },
      },
      {
        icon: { name: 'info circle' },
        index: 1,
        key: 'summary',
        title: ({ user }) => !!user && user.displayName,
      },
    ];
  }

  @action
  setEmployerDetailsTab(tab) {
    this.employerDetails.activeTab = tab;
  }

  /* Admin User Details ... */

  /* Tabs ________________________________ */
  @observable
  partnerDetails = {
    activeTab: 'summary',
    mapSurveys: [],
    showMap: false,
  };

  @computed
  get partnerDetailsTabs() {
    return [
      {
        icon: { name: 'info circle' },
        index: 1,
        key: 'summary',
      },
      {
        index: 2,
        key: 'tags',
        title: 'Tags',
      },
      {
        index: 2.2,
        key: 'shiftBasedStatsHistory',
        title: 'Shift Based Stats History',
      },
      {
        index: 3,
        key: 'work',
        title: 'Work',
      },
      {
        index: 4,
        key: 'shops',
        onSelect: this.loadUserShops,
        title: 'Shops',
      },
      {
        index: 5,
        key: 'trainings',
        onSelect: this.loadUserTrainings,
        title: 'Trainings',
      },
      {
        index: 6,
        key: 'onboardings',
        title: 'Onboardings',
      },
      {
        index: 7,
        key: 'companies',
        title: 'Companies',
      },
      {
        index: 8,
        key: 'authorizedDevices',
        title: 'Authorized Devices',
      },
      {
        index: 9,
        key: 'fraudViolations',
        title: 'Fraud Violations',
      },
      {
        index: 10,
        key: 'notifications',
        onSelect: this.loadUserNotifications,
        title: 'Notifications',
      },
      {
        index: 11,
        key: 'data',
        title: 'Data',
      },
      {
        disabled: ({ user }) => _.isEmpty(user.resumeImageURL),
        index: 21,
        key: 'resume',
        title: 'Resume',
      },
      {
        index: 22,
        key: 'audit',
        title: 'Audit',
      },
      {
        index: 23,
        key: 'payments',
        title: 'Payments',
      },
      {
        index: 24,
        key: 'bonuses',
        title: 'Bonuses',
      },
      {
        index: 25,
        key: 'backgroundChecks',
        title: 'Background Checks',
      },
      {
        disabled: ({ user }) => _.isEmpty(user.phoneCarrierInformation),
        index: 26,
        key: 'carrierInformation',
        title: 'Carrier Information',
      },
      {
        index: 27,
        key: 'documentVault',
        title: 'Document Vault',
      },
      {
        index: 28,
        key: 'termsOfService',
        title: 'Terms of Service',
      },
    ];
  }

  @action
  setPartnerDetailsTab(tab) {
    this.partnerDetails.activeTab = tab;
  }

  async loadUserNotifications({ user }) {
    const userId = _.get(user, 'uuid', user);

    if (_.isEmpty(userId) || !_.isString(userId)) {
      return Promise.reject('unable to determine User ID');
    }

    return Promise.all([
      dispatch(
        'notificationsV2.find',
        {
          user: userId,
        },
        { clear: true },
      ),
      dispatch(
        'notifications.find',
        {
          $sort: { createdAt: -1 },
          user: userId,
        },
        { clear: true },
      ),
    ]);
  }

  @action.bound
  async loadUserTrainings({ user }) {
    this.user = user;
    if (!user || _.isEmpty(user.uuid)) {
      return false;
    }

    const query = {
      $limit: 20,
      'data.isTraining': true,
      dataType: 'survey',
      user: this.user.uuid,
    };

    return dispatch('assignments.find', _.extend({}, query, { $limit: 20 }), {
      clear: true,
    });
  }

  @action.bound
  async loadUserShops({ user }) {
    this.user = user;
    if (!user || _.isEmpty(user.uuid)) {
      return false;
    }

    const query = {
      $limit: 20,
      $sort: {
        updatedAt: -1,
      },
      campaign: { $ne: null },
      dataType: 'survey',
      user: this.user.uuid,
    };

    return dispatch('assignments.find', _.extend({}, query, { $limit: 20 }), {
      clear: true,
    });
  }

  @action.bound
  async loadMapSurveys({ user, center, radius }) {
    this.user = user;
    if (!user || _.isEmpty(user.uuid)) {
      return false;
    }

    const query = {
      dataType: 'survey',
      loc: {
        coordinates: center,
        type: 'Point',
      },
      radius,
      userId: this.user.uuid,
    };

    try {
      const response = await dispatch('surveys.loadUserWorkSurveys', { query });
      const data = response.work.map((survey) =>
        _.extend({}, survey, { loc: survey.store.loc }),
      );
      return this.setMapSurveys(data);
    } catch (error) {
      log.error('Error Loading user work in admin view: ', error);
      return dispatch('ui.snackBar.open', error.message);
    }
  }

  @action.bound
  setMapSurveys(surveys) {
    this.partnerDetails.mapSurveys = surveys;
  }

  @action.bound
  getSignupChartOptions({ reset = false } = {}) {
    const { signups, cleared } = this.summaryData;
    const baseArray = _.times(this.dateRange.length, () => 0);

    const data = {
      cleared: _.clone(baseArray),
      clearedCum: _.clone(baseArray),
      signupCum: _.clone(baseArray),
      signups: _.clone(baseArray),
    };

    _(signups).each((signupDay) => {
      const i = _.findIndex(this.dateRange, {
        doy: signupDay.id.doy,
        year: signupDay.id.year,
      });

      data.signups[i] = signupDay.count;
    });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.signups.length; i++) {
      if (i === 0) data.signupCum[i] = data.signups[i];
      else data.signupCum[i] = data.signups[i] + data.signupCum[i - 1];
    }

    _(cleared).each((clearedDay) => {
      const i = _.findIndex(this.dateRange, {
        doy: clearedDay.id.doy,
        year: clearedDay.id.year,
      });

      data.cleared[i] = clearedDay.count;
    });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.cleared.length; i++) {
      if (i === 0) data.clearedCum[i] = data.cleared[i];
      else {
        data.clearedCum[i] = data.cleared[i] + data.clearedCum[i - 1];
      }
    }

    const options = {
      legend: {
        display: true,
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            id: 'y-axis-data',
            max: Math.max(_.max(data.signups), _.max(data.cleared)),
            min: 0,
            position: 'left',
          },
          {
            id: 'y-axis-cum',
            max: Math.max(_.max(data.signupCum), _.max(data.clearedCum)),
            min: 0,
            position: 'right',
            stacked: true,
          },
        ],
      },
      title: {
        display: true,
        text: 'Partner Signups Over Time',
      },
      tooltips: {
        mode: 'label',
      },
    };

    const stats = {
      avgDay: undefined,
      dailyMax: _.max(data.signups),
      daysCount: _.size(this.dateRange),
      total: Math.max(_.max(data.signupCum), _.max(data.clearedCum)),
    };
    if (stats.daysCount) {
      stats.avgDay = stats.total / stats.daysCount;
    }

    const colors = getColorPalette(2);
    let colorIndex = 0;
    // eslint-disable-next-line no-plusplus
    const baseColor = colors[colorIndex++ % colors.length];

    const datasets = [
      getDataset({
        color: baseColor,
        data: data.signups,
        label: 'Partner Signups by Day',
        yAxisID: 'y-axis-data',
      }),
      // getDataset({
      //   label: 'Cleared Profiles per Day',
      //   data: data.cleared,
      //   yAxisID: 'y-axis-data',
      //   color: secondColor,
      // }),
      getDataset({
        color: baseColor,
        data: data.signupCum,
        label: 'Total Signups',
        yAxisID: 'y-axis-cum',
      }),
      // getDataset({
      //   label: 'Total Cleared Profiles',
      //   data: data.clearedCum,
      //   yAxisID: 'y-axis-cum',
      //   color: secondColor,
      // }),
    ];

    const labels = _.map(this.dateRange, 'formatted');
    const { signupChart: chart } = this.chartRefs;

    if (reset && chart && _.isFunction(chart.update)) {
      chart.data.labels = labels;
      chart.data.datasets = datasets;
      chart.options = options;
      chart.stats = stats;

      chart.update();
    } else {
      set(this.chartData, 'signupChartOptions', {
        data: {
          datasets,
          labels,
        },
        options,
        stats,
      });
    }

    return this.chartData.signupChartOptions;
  }

  @action.bound
  getShiftChartOptions({ reset = false } = {}) {
    const { ptss } = this.summaryData;
    const baseArray = _.times(this.dateRange.length, () => 0);

    const grouped = _(ptss).groupBy('id.type').omit(['undefined']).value();

    const maxVals = {
      cum: 0,
      daily: 0,
    };

    const colors = getColorPalette(_.size(grouped.length));
    let colorIndex = 0;

    const datasets = _(grouped)
      .map((values, key) => {
        const processedGroup = _.reduce(
          values,
          (acc, s) => {
            const i = _.findIndex(this.dateRange, {
              doy: s.id.doy,
              year: s.id.year,
            });

            acc.data[i] = s.count;
            return acc;
          },
          { cum: _.clone(baseArray), data: _.clone(baseArray) },
        );

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < processedGroup.data.length; i++) {
          if (i === 0) processedGroup.cum[i] = processedGroup.data[i];
          else {
            processedGroup.cum[i] =
              processedGroup.data[i] + processedGroup.cum[i - 1];
          }
        }

        maxVals.daily = Math.max(maxVals.daily, _.max(processedGroup.data));
        maxVals.cum = Math.max(maxVals.cum, _.max(processedGroup.cum));

        // eslint-disable-next-line no-plusplus
        const baseColor = colors[colorIndex++ % colors.length];

        return [
          getDataset({
            color: baseColor,
            data: processedGroup.data,
            label: `${_.capitalize(key)} by Day`,
            yAxisID: 'y-axis-data',
          }),
          getDataset({
            color: baseColor,
            data: processedGroup.cum,
            label: `Total ${_.capitalize(key)}`,
            yAxisID: 'y-axis-cum',
          }),
        ];
      })
      .flatten()
      .value();

    const options = {
      legend: {
        display: true,
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            id: 'y-axis-data',
            max: maxVals.daily,
            min: 0,
            position: 'left',
          },
          {
            id: 'y-axis-cum',
            max: maxVals.cum,
            min: 0,
            position: 'right',
            stacked: true,
          },
        ],
      },
      title: {
        display: true,
        text: 'PTS Over Time',
      },
      tooltips: {
        mode: 'label',
      },
    };

    const labels = _.map(this.dateRange, 'formatted');
    const { shiftChart: chart } = this.chartRefs;

    if (reset && chart && _.isFunction(chart.update)) {
      chart.data.labels = labels;
      chart.data.datasets = datasets;
      chart.options = options;

      chart.update();
    } else {
      set(this.chartData, 'shiftChartOptions', {
        data: {
          datasets,
          labels,
        },
        options,
      });
    }

    return this.chartData.shiftChartOptions;
  }

  @action.bound
  getAssignmentChartOptions({ reset = false } = {}) {
    const { assignments } = this.summaryData;
    const baseArray = _.times(this.dateRange.length, () => 0);

    const grouped = _.groupBy(assignments, 'id.type');

    const maxVals = {
      cum: 0,
      daily: 0,
    };

    const colors = getColorPalette(_.size(grouped.length));
    let colorIndex = 0;

    const datasets = _(grouped)
      .map((values, key) => {
        const processedGroup = _.reduce(
          values,
          (acc, s) => {
            const i = _.findIndex(this.dateRange, {
              doy: s.id.doy,
              year: s.id.year,
            });

            acc.data[i] = s.count;
            return acc;
          },
          { cum: _.clone(baseArray), data: _.clone(baseArray) },
        );

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < processedGroup.data.length; i++) {
          if (i === 0) processedGroup.cum[i] = processedGroup.data[i];
          else {
            processedGroup.cum[i] =
              processedGroup.data[i] + processedGroup.cum[i - 1];
          }
        }

        maxVals.cum = Math.max(maxVals.cum, _.max(processedGroup.cum));
        maxVals.daily = Math.max(maxVals.daily, _.max(processedGroup.data));

        // eslint-disable-next-line no-plusplus
        const baseColor = colors[colorIndex++ % colors.length];

        return [
          getDataset({
            color: baseColor,
            data: processedGroup.data,
            label: `Completed ${_.capitalize(key)} by Day`,
            yAxisID: 'y-axis-data',
          }),
          getDataset({
            color: baseColor,
            data: processedGroup.cum,
            label: `Total ${_.capitalize(key)}`,
            yAxisID: 'y-axis-cum',
          }),
        ];
      })
      .flatten()
      .value();

    const options = {
      legend: {
        display: true,
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            id: 'y-axis-data',
            max: maxVals.daily,
            min: 0,
            position: 'left',
          },
          {
            id: 'y-axis-cum',
            max: maxVals.cum,
            min: 0,
            position: 'right',
            stacked: true,
          },
        ],
      },
      title: {
        display: true,
        text: 'Completed Assignments Over Time',
      },
      tooltips: {
        mode: 'label',
      },
    };

    const labels = _.map(this.dateRange, 'formatted');
    const { assignmentChart: chart } = this.chartRefs;

    if (reset && chart && _.isFunction(chart.update)) {
      chart.data.labels = labels;
      chart.data.datasets = datasets;
      chart.options = options;

      chart.update();
    } else {
      set(this.chartData, 'assignmentChartOptions', {
        data: {
          datasets,
          labels,
        },
        options,
      });
    }

    return this.chartData.assignmentChartOptions;
  }

  /** #region settings */
  @action
  async initCompanySettings(company = this.company) {
    this.initScheduleUploaderSettings({
      value: _.get(company, 'settings.scheduleUploader', ''),
    });
    this.setInstantPayFee(
      _.get(company, 'settings.payments.defaultInstantPayFee', 1),
    );
    this.setPayPeriodStartDate(
      moment(_.get(company, 'settings.payments.payPeriodStartDate')),
    );
    _.get(company, 'settings.timekeeping.breakOptions', []).forEach(
      (breakOption, index) => {
        this.setBreakDuration(index, breakOption.breakDuration);
      },
    );
    this.setFillRateThreshold(
      _.get(company, 'settings.dashboard.fillRateThreshold', null),
    );
    this.setLaborCostsThreshold(
      _.get(company, 'settings.dashboard.laborCostsThreshold', null),
    );
    this.setPaymentHistoryCustomCopy(
      _.get(company, 'settings.payments.paymentHistoryCustomCopy', ''),
    );
    this.setExpectedPaymentCustomCopy(
      _.get(company, 'settings.payments.expectedPaymentCustomCopy'),
    );
    await dispatch('dispatchPrefs.find', { companies: this.company.uuid });
  }

  @computed
  get shiftsDefaultSetupOptions() {
    return [
      {
        key: 'list',
        text: 'List View',
        value: 'view=list',
      },
      {
        key: 'listDay',
        text: 'List Day View',
        value: 'view=list&range=day',
      },
      {
        key: 'day',
        text: 'Day Calendar View',
        value: 'view=calendar&range=day',
      },
      {
        key: 'week',
        text: 'Week Calendar View',
        value: 'view=calendar&range=week',
      },
    ];
  }

  @computed
  get checkinDistanceOptions() {
    return [
      {
        key: 0.1,
        text: '0.1 miles',
        value: 0.1,
      },
      {
        key: 0.25,
        text: '0.25 miles',
        value: 0.25,
      },
      {
        key: 0.5,
        text: '0.5 miles',
        value: 0.5,
      },
      {
        key: 0.75,
        text: '0.75 miles',
        value: 0.75,
      },
      {
        key: 1,
        text: '1 mile',
        value: 1,
      },
      {
        key: 2,
        text: '2 miles',
        value: 2,
      },
      {
        key: 3,
        text: '3 miles',
        value: 3,
      },
      {
        key: 4,
        text: '4 miles',
        value: 4,
      },
      {
        key: 5,
        text: '5 miles',
        value: 5,
      },
      {
        key: 6,
        text: '6 miles',
        value: 6,
      },
      {
        key: 7,
        text: '7 miles',
        value: 7,
      },
      {
        key: 8,
        text: '8 miles',
        value: 8,
      },
      {
        key: 9,
        text: '9 miles',
        value: 9,
      },
      {
        key: 10,
        text: '10 miles',
        value: 10,
      },
    ];
  }

  @computed
  get confirmShiftTimeOptions() {
    return [
      {
        key: 1440,
        text: '24 hours',
        value: 1440,
      },
      {
        key: 2160,
        text: '36 hours',
        value: 2160,
      },
      {
        key: 2880,
        text: '48 hours',
        value: 2880,
      },
      {
        key: 3600,
        text: '60 hours',
        value: 3600,
      },
      {
        key: 4320,
        text: '72 hours',
        value: 4320,
      },
    ];
  }

  @observable
  shiftScheduleUploaderOptions = [];

  @action
  initScheduleUploaderSettings({ value }) {
    this.shiftScheduleUploaderOptions = [
      {
        key: 'baseShiftUploader',
        text: 'Base Shift Uploader',
        value: 'baseShiftUploader',
      },
      {
        key: 'monthlyScheduleUpload',
        text: 'Monthly Schedule Upload (Plum)',
        value: 'monthlyScheduleUpload',
      },
      {
        key: 'weeklyScheduleUpload',
        text: 'Weekly Schedule Upload (Subway)',
        value: 'weeklyScheduleUpload',
      },
    ];

    this.selectScheduleUploader({ value });
  }

  @computed
  get scheduleUploaderIsDirty() {
    return (
      this.shiftScheduleUploader !==
      _.get(this.company, 'settings.scheduleUploader', '')
    );
  }

  @observable shiftScheduleUploader = '';

  @action selectScheduleUploader({ value }) {
    const val = _.find(this.shiftScheduleUploaderOptions, { value });

    if (!val) {
      this.shiftScheduleUploaderOptions.push({
        text: value,
        value,
      });
    }

    this.shiftScheduleUploader = value;
  }

  async saveScheduleUploader({ user, company = this.company }) {
    const co = await dispatch('companies.patchSettings', {
      company,
      key: 'scheduleUploader',
      user,
      value: this.shiftScheduleUploader,
    });
    // this.selectCompany(co);
    this.initCompanySettings(co);
  }

  @computed
  get payPeriodOptions() {
    return [
      {
        key: 'weekly',
        text: 'Every 1 Week',
        value: 'weekly',
      },
      {
        key: 'biweekly',
        text: 'Every 2 Weeks',
        value: 'biweekly',
      },
    ];
  }

  @observable payPeriodStartDate = moment();

  @action
  setPayPeriodStartDate(val) {
    this.payPeriodStartDate = val;
  }

  @action
  setFillRateThreshold(val) {
    this.dashboard.fillRateThreshold = val;
  }

  @action
  setPaymentHistoryCustomCopy(val) {
    this.paymentHistoryCustomCopy = val;
  }

  @action
  setExpectedPaymentCustomCopy(val) {
    this.expectedPaymentCustomCopy = val;
  }

  @action
  savePaymentHistoryCustomCopy({ user = this.user, company = this.company }) {
    dispatch('companies.patchSettings', {
      company,
      key: 'payments.paymentHistoryCustomCopy',
      user,
      value: this.paymentHistoryCustomCopy,
    });
  }

  @action
  saveExpectedPaymentCustomCopy({ user = this.user, company = this.company }) {
    dispatch('companies.patchSettings', {
      company,
      key: 'payments.expectedPaymentCustomCopy',
      user,
      value: this.expectedPaymentCustomCopy,
    });
  }

  @action
  setLaborCostsThreshold(val) {
    this.dashboard.laborCostsThreshold = val;
  }

  @action
  saveFillRateThreshold({ user, company = this.company }) {
    dispatch('companies.patchSettings', {
      company,
      key: 'dashboard.fillRateThreshold',
      user,
      value: _.toNumber(this.dashboard.fillRateThreshold),
    });
  }

  @action
  saveLaborCostsThreshold({ user, company = this.company }) {
    dispatch('companies.patchSettings', {
      company,
      key: 'dashboard.laborCostsThreshold',
      user,
      value: _.toNumber(this.dashboard.laborCostsThreshold),
    });
  }

  @action
  savePayPeriodStartDate({ user, company = this.company }) {
    dispatch('companies.patchSettings', {
      company,
      key: 'payments.payPeriodStartDate',
      user,
      value: this.payPeriodStartDate,
    });
  }

  @observable defaultInstantPayFee = 1;

  @action
  setInstantPayFee(val) {
    this.defaultInstantPayFee = val;
  }

  @action
  saveInstantPayFee({ user, company = this.company }) {
    dispatch('companies.patchSettings', {
      company,
      key: 'payments.defaultInstantPayFee',
      user,
      value: this.defaultInstantPayFee,
    });
  }

  @action
  async addTraining({ user }) {
    const userId = _.get(user, 'uuid', user);
    try {
      const surveyDef = '81c48f62-ca54-4fda-a986-d1d014439bf9';
      const existingTraining = await dispatch('assignments.runQuery', {
        query: {
          'data.isTraining': true,
          'data.surveyDef.uuid': surveyDef,
          user: userId,
        },
      });
      const trainingAssignment: IAssignment = _.first(existingTraining.data);
      if (!_.isEmpty(trainingAssignment)) {
        dispatch(
          'ui.snackBar.error',
          `${_.get(
            user,
            'displayName',
            user,
          )} already has MS101 Training. Assignment status: "${
            trainingAssignment.status
          }"`,
        );
        return Promise.resolve({});
      }
      const survey = await dispatch('surveys.create', {
        data: {
          company: '8ab33b07-fe61-425a-8cda-ec2ee826d305',
          description: 'Complete & Pass this training to start earning now!',
          isTraining: true,
          status: 'Pending',
          surveyDef,
          title: 'Mystery Shopping Training',
        },
      });

      log.debug('Created Survey Thing: ', survey);

      const assignment = await dispatch('assignments.create', {
        data: {
          data: survey,
          dataType: 'survey',
          ref: survey.uuid,
          status: 'Assigned',
          user: userId,
        },
      });

      log.debug('Created Survey Assignment', { assignment });
      dispatch(
        'ui.snackBar.open',
        `Created MS101 Training Assignment for ${_.get(
          user,
          'displayName',
          user,
        )}`,
      );
    } catch (err) {
      log.error('Error Creating Training Assignment: ', err);
    }

    return Promise.resolve({});
  }

  @observable defaultBreakDuration = [0, 0];

  @action
  setBreakDuration(index, val) {
    this.defaultBreakDuration[index] = parseInt(val, 10);
  }

  @action
  reenableAccount({ worker }) {
    dispatch('workers.update', {
      data: { disabledBy: null, isDisabled: false },
      id: worker.uuid,
    });
  }

  @action
  disableAccount({ worker, admin }) {
    dispatch('workers.update', {
      data: { disabledBy: admin.uuid, isDisabled: true },
      id: worker.uuid,
    });
  }

  @action
  async exemptUser({ user }) {
    await dispatch('workers.update', {
      data: {
        isExemptFromSuspensions: true,
      },
      id: user.uuid,
    });
    dispatch('partnerRoleStats.unsuspendUser', {
      userId: user.uuid,
    });
  }

  @observable _settingsFilter = '';

  @computed get settingsFilter() {
    return _.toLower(this._settingsFilter);
  }

  @action.bound setSettingsFilter(value) {
    this._settingsFilter = value || '';
  }
}

function getDataset({
  key,
  label,
  data,
  yAxisID,
  ...rest
}: ({ key?: string; label: string } | { key: string; label?: string }) & {
  color: string;
  data: any;
  yAxisID: string;
} & Record<string, unknown>) {
  let baseColor: any = { blue: 196, green: 108, red: 42 };

  if (_.has(rest, 'color')) {
    baseColor =
      (_.isString(rest.color)
        ? hexRgb(`#${rest.color}`.replace('##', '#'))
        : rest.color) || baseColor;
  }

  const borderColor = `rgba(${[
    baseColor.red,
    baseColor.green,
    baseColor.blue,
    0.5,
  ].join(', ')})`;
  const pointBorderColor = `rgba(${[
    baseColor.red,
    baseColor.green,
    baseColor.blue,
    0.6,
  ].join(', ')})`;
  const pointHoverBorderColor = `rgba(${[
    baseColor.red,
    baseColor.green,
    baseColor.blue,
    1,
  ].join(', ')})`;
  const pointHoverBackgroundColor = `rgba(${[
    baseColor.red,
    baseColor.green,
    baseColor.blue,
    1,
  ].join(', ')})`;

  return _.extend(
    {
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderCapStyle: 'butt',
      borderColor,
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      data,
      fill: false,
      label: label || `Total ${_.capitalize(key)}`,
      lineTension: 0.1,
      pointBackgroundColor: '#fff',
      pointBorderColor,
      pointBorderWidth: 1,
      pointHitRadius: 10,
      pointHoverBackgroundColor,
      pointHoverBorderColor,
      pointHoverBorderWidth: 2,
      pointHoverRadius: 5,
      pointRadius: 1,
      yAxisID,
    },
    rest,
  );
}

function getColorPalette(count = 10) {
  const colors = palette('tol-rainbow', count || 10).reverse();
  colors.unshift({ blue: 196, green: 108, red: 42 });
  return colors;
}

const validTabsRegExp = new RegExp(
  `^(${[
    '',
    'users',
    'certs',
    'positions',
    'badges',
    'quals',
    'skills',
    'companies',
    'experiences',
    'industries',
    'roles',
    'taggroups',
    'attributes',
    'messageTags',
    'pools',
    'employers',
    'favorites',
    'utils',
    'accounts',
    'paymentAccounts',
    'transactions',
    'paymentUpload',
    'videoInterviews',
    'zones',
  ].join('|')})$`,
  'i',
);

/**
 * @name isValidAdminTab
 * @description returns true if the given argument is a valid/existing admin tab
 * @param tab
 * @returns boolean
 */
function isValidAdminTab(tab: string) {
  return validTabsRegExp.test(tab);
}
