import type { ITosAcceptance } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export default class TermsOfServiceAcceptanceStore extends BaseStore<ITosAcceptance> {
  constructor() {
    super({
      baseItem: TermsOfServiceAcceptanceStore.BASE_ITEM,
      serviceName: 'tosacceptances',
    });

    return this;
  }

  static BASE_ITEM: ITosAcceptance = {
    ...BaseStore.BASE_ITEM,
    acceptedAt: null,
    agreementURL: null,
    appVersion: null,
    device: null,
    ipAddress: null,
    tosVersion: null,
    userId: null,
    uuid: null,
  };
}
