import React, { MouseEventHandler } from 'react';
import { observer } from 'mobx-react';
import { dispatch } from 'rfx-core';
import cx from 'classnames';
import styled, { css, StyledComponent } from 'styled-components';
import { Menu, Header } from 'semantic-ui-react';
import _ from 'lodash';

import styles from '#/shared/styles/AppNav.css';
import { useStores } from '#/shared/hooks/useStores';
import PopupIcon from '#/shared/components/ssm/PopupIcon';
import { getIconDefinition } from '#/shared/components/utils/tagUtils';

const setActivePane = (name) => {
  dispatch('ui.admin.setActiveItem', name);
};

const handleReturnToPortal = () => {
  // Check if company is selected
  const company = dispatch('auth.getCompany');
  if (company?.uuid) {
    dispatch('routing.push', '/');
  } else {
    dispatch(
      'ui.snackBar.error',
      'Please select company before entering portal',
    );
  }
};

export const CollapsibleMenuItem: React.FC<{
  active?: boolean;
  collapsed: boolean;
  content: string;
  icon: unknown;
  indented?: boolean;
  name: string;
  onClick: (e: any, name) => void;
}> = observer(
  ({ name, content, icon, active, onClick, collapsed, indented, ...rest }) => {
    const iconDef = icon?.name ?? icon;
    const iconName = active ? iconDef.replace('outline', '') : iconDef;
    const iconProps = _.isObject(icon)
      ? { ...icon, name: iconName }
      : { name: iconName };
    const iconComp = (
      <PopupIcon
        offset={[0, 10]}
        popperModifiers={[
          {
            name: 'preventOverflow',
            options: {
              boundariesElement: 'offsetParent',
            },
          },
        ]}
        position="right center"
        tooltip={collapsed && content}
        {...iconProps}
      />
    );

    const trigger = (
      <Menu.Item
        style={indented || collapsed ? undefined : { paddingLeft: 0 }}
        {...{
          active,
          icon: iconComp,
          name,
          onClick,
        }}
        content={
          !collapsed && (
            <div className={cx(active && 'b')}>
              {content}
              {active && <div className={styles.activeTriangle} />}
            </div>
          )
        }
        {...rest}
      />
    );

    return trigger;
  },
);

CollapsibleMenuItem.displayName = 'CollapsibleMenuItem';

const SectionHeader: React.FC<{ collapsed: boolean; content: unknown }> =
  observer(({ content, collapsed }) =>
    collapsed ? (
      <hr style={{ margin: '0.5rem 0' }} />
    ) : (
      <Menu.Header
        as={Header}
        content={content}
        dividing={true}
        inverted={true}
        size="small"
        style={{ marginBottom: 0, marginTop: '0.5rem' }}
      />
    ),
  );

SectionHeader.displayName = 'SectionHeader';

type NavToggleHandleProps = {
  /** Width in pixels that the collapsed nav bar should render as */
  collapsedWidth: number;
  isCollapsed: boolean;
};
type NavToggleComputedProps = NavToggleHandleProps & {
  borderBottomLeftRadius;
  borderBottomRightRadius;
  borderTopLeftRadius;
  borderTopRightRadius;
  /** The left offset (number of pixels) */
  left?: number;
  /** The right offset (number of pixels) */
  right?: number;
};

export const NavToggleHandle: React.FC<
  NavToggleHandleProps & { onClick: MouseEventHandler<HTMLDivElement> }
> = observer(({ onClick, collapsedWidth, isCollapsed }) => (
  <PopupIcon
    delay={{
      close: 0,
      open: 700,
    }}
    inverted={false}
    offset={[-15, 0]}
    popperModifiers={[
      {
        name: 'preventOverflow',
        options: {
          boundariesElement: 'offsetParent',
        },
      },
    ]}
    position="right center"
    tooltip={`Click to ${isCollapsed ? 'expand' : 'collapse'}`}
    trigger={
      <ExpandoCollapsoHandle
        collapsedWidth={collapsedWidth}
        isCollapsed={isCollapsed}
        onClick={onClick}
      />
    }
  />
));

const ExpandoCollapsoHandle: StyledComponent<
  'div',
  any,
  NavToggleHandleProps,
  'className'
> = styled.div.attrs<NavToggleHandleProps>(
  ({ isCollapsed, collapsedWidth }) => ({
    borderBottomLeftRadius: !isCollapsed ? 4 : 0,

    borderBottomRightRadius: isCollapsed ? 4 : 0,

    borderTopLeftRadius: !isCollapsed ? 4 : 0,

    borderTopRightRadius: isCollapsed ? 4 : 0,

    className: 'absolute',
    /**
     * When the nav bar is coolapsed we explicitly set the left as navigation bar width (Fixed 70 px right now)
     * For regular (not collapsed) nav bar, the collapse bar will automatically be placed after the nav bar
     */
    left: isCollapsed ? collapsedWidth : undefined,
    right: !isCollapsed && 0,
  }),
)<NavToggleComputedProps>`
  cursor: pointer;
  background-color: 'transparent';
  width: 4px;
  height: calc(100% - 20px);
  top: 10px;
  bottom: 10px;
  z-index: 10;
  ${({ left }) =>
    _.isNumber(left) &&
    css`
      left: ${`${left}px`};
    `}
  ${({ right }) =>
    _.isNumber(right) &&
    css`
      right: ${`${right}px`};
    `}
  transition: width 200ms 500ms, color 200ms 500ms;
  :hover {
    width: 0.5rem;
    background-color: #00000033;
  }
  border-radius: ${({
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    borderTopLeftRadius,
  }) =>
    _.map(
      [
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomRightRadius,
        borderBottomLeftRadius,
      ],
      (br) => `${br}px`,
    ).join(' ')};
`;

const AdminNavBar: React.FC<{
  activeItem: string;
  collapsed: boolean;
}> = observer(({ activeItem, collapsed }) => {
  const { ui } = useStores();
  const handleItemClick = (e, { name }) => setActivePane(name);
  const fluid = !collapsed;
  const purple = false;

  const showProcessingQueueBeta = !!localStorage.getItem('ssm-1142');

  return (
    <div className="h-100 w-100 flex-column pv2 ssm-sidebar-menu relative flex">
      <div
        className={cx(
          'h-50 w-100 flex-grow-1 overflow-y-scroll',
          collapsed && 'flex justify-center',
        )}
      >
        <Menu
          borderless={true}
          className={styles.adminBar}
          fluid={fluid}
          icon={collapsed}
          inverted={true}
          style={purple && collapsed ? { width: '2.5rem' } : {}}
          vertical={true}
        >
          <CollapsibleMenuItem
            active={activeItem === ''}
            collapsed={collapsed}
            content="Dashboard"
            icon="dashboard"
            name=""
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'companies'}
            collapsed={collapsed}
            content="Companies"
            icon="industry"
            name="companies"
            onClick={handleItemClick}
          />
          <SectionHeader content="Accounts" {...{ collapsed }} />
          <CollapsibleMenuItem
            active={activeItem === 'users'}
            collapsed={collapsed}
            content="Partners"
            icon="address book outline"
            indented={true}
            name="users"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'employers'}
            collapsed={collapsed}
            content="Employers"
            icon="warehouse"
            indented={true}
            name="employers"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'administrators'}
            collapsed={collapsed}
            content="Administrators"
            icon="user secret"
            indented={true}
            name="administrators"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'newusers'}
            collapsed={collapsed}
            content="Partner Profiles"
            icon="map outline"
            indented={true}
            name="newusers"
            onClick={handleItemClick}
          />
          <SectionHeader {...{ collapsed }} content="Partner Tagging" />
          <CollapsibleMenuItem
            active={activeItem === 'certs'}
            collapsed={collapsed}
            content="Certs"
            icon={getIconDefinition('certs', activeItem)}
            indented={true}
            name="certs"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'quals'}
            collapsed={collapsed}
            content="Qualifications"
            icon={getIconDefinition('quals', activeItem)}
            indented={true}
            name="quals"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'positions'}
            collapsed={collapsed}
            content="Positions"
            icon={getIconDefinition('positions', activeItem)}
            indented={true}
            name="positions"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'taggroups'}
            collapsed={collapsed}
            content="Tag Groups"
            icon={getIconDefinition('taggroups', activeItem)}
            indented={true}
            name="taggroups"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'badges'}
            collapsed={collapsed}
            content="Badges"
            icon={getIconDefinition('badges', activeItem)}
            indented={true}
            name="badges"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'attributes'}
            collapsed={collapsed}
            content="Attributes"
            icon={getIconDefinition('attributes', activeItem)}
            indented={true}
            name="attributes"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'experiences'}
            collapsed={collapsed}
            content="Work History"
            icon={getIconDefinition('experiences', activeItem)}
            indented={true}
            name="experiences"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'roles'}
            collapsed={collapsed}
            content="Roles"
            icon={getIconDefinition('roles', activeItem)}
            indented={true}
            name="roles"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'skills'}
            collapsed={collapsed}
            content="Skills"
            icon={getIconDefinition('skills', activeItem)}
            indented={true}
            name="skills"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'industries'}
            collapsed={collapsed}
            content="Industries"
            icon={getIconDefinition('industries', activeItem)}
            indented={true}
            name="industries"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'messageTags'}
            collapsed={collapsed}
            content="Message Tags"
            icon="chat"
            indented={true}
            name="messageTags"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'videoInterviews'}
            collapsed={collapsed}
            content="Video Interviews"
            icon={getIconDefinition('experience', activeItem)}
            indented={true}
            name="videoInterviews"
            onClick={handleItemClick}
          />
          <SectionHeader {...{ collapsed }} content="Collections" />
          <CollapsibleMenuItem
            active={activeItem === 'pools'}
            collapsed={collapsed}
            content="Pools"
            icon="bullseye"
            indented={true}
            name="pools"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'opportunities'}
            collapsed={collapsed}
            content="Opportunities"
            icon="sitemap"
            indented={true}
            name="opportunities"
            onClick={handleItemClick}
          />
          <SectionHeader {...{ collapsed }} content="Payments" />
          <CollapsibleMenuItem
            active={/^paymentAccounts/.test(activeItem)}
            collapsed={collapsed}
            content="Accounts"
            icon="user"
            indented={true}
            name="paymentAccounts"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={/^transactions/.test(activeItem)}
            collapsed={collapsed}
            content="Transactions"
            icon="dollar"
            indented={true}
            name="transactions"
            onClick={handleItemClick}
          />
          {showProcessingQueueBeta && (
            <CollapsibleMenuItem
              active={/^PaymentProcessingQueue/.test(activeItem)}
              collapsed={collapsed}
              content="Processing Queue"
              icon="inbox"
              indented={true}
              name="paymentProcessingQueue"
              onClick={handleItemClick}
            />
          )}
          <CollapsibleMenuItem
            active={activeItem === 'audit'}
            collapsed={collapsed}
            content="Audit"
            icon="archive"
            name="audit"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'phoneVerification'}
            collapsed={collapsed}
            content="Phone Verifications"
            icon="phone"
            name="phoneVerification"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'favorites'}
            collapsed={collapsed}
            content="Favorites"
            icon="star"
            name="favorites"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'utils'}
            collapsed={collapsed}
            content="Utilities"
            icon="settings"
            name="utils"
            onClick={handleItemClick}
          />
          <CollapsibleMenuItem
            active={activeItem === 'zones'}
            collapsed={collapsed}
            content="Zones"
            icon="map"
            name="zones"
            onClick={handleItemClick}
          />
        </Menu>
      </div>

      <div className={cx(collapsed && 'flex justify-center')}>
        <Menu
          fluid={fluid}
          icon={collapsed}
          inverted={true}
          style={purple && collapsed ? { width: '2.5rem' } : {}}
          vertical={true}
        >
          <CollapsibleMenuItem
            collapsed={collapsed}
            content="Return to Portal"
            icon={{ flipped: 'horizontally', name: 'external alternate' }}
            name="exit"
            onClick={handleReturnToPortal}
          />
        </Menu>
      </div>

      <NavToggleHandle
        collapsedWidth={ui.navBarWidth - 14} // In admin panel, the left nav bar has additional space of 13 px
        isCollapsed={ui.isNavCollapsed}
        onClick={() => ui.setNavCollapsed()}
      />
    </div>
  );
});

AdminNavBar.displayName = 'AdminNavBar';
export default AdminNavBar;
